import axios from 'axios'

// ANAF OAuth configuration from the provided credentials
const ANAF_OAUTH_CONFIG = {
    clientId     : 'd5ebc92948da95378a61b2a108b32edd0c58d20f290d7d66',
    clientSecret : 'e54e390ee6d4ae1c4e8686f3d9b4307734a1b9f72e382edd0c58d20f290d7d66',
    authUrl      : 'https://logincert.anaf.ro/anaf-oauth2/v1/authorize',
    tokenUrl     : 'https://logincert.anaf.ro/anaf-oauth2/v1/token',
    revokeUrl    : 'https://logincert.anaf.ro/anaf-oauth2/v1/revoke',
    redirectUri  : 'https://e-transport.aws.safefleet.eu/oauth',
    scope        : 'e-transport', // Assuming the scope based on the service shown in the image
    grantType    : 'authorization_code',
}

/**
 * Initiates the ANAF OAuth flow by redirecting to the ANAF login page
 */
export const initiateAnafLogin = () => {
    const authUrl = new URL(ANAF_OAUTH_CONFIG.authUrl)
    authUrl.searchParams.append('client_id', ANAF_OAUTH_CONFIG.clientId)
    authUrl.searchParams.append('redirect_uri', ANAF_OAUTH_CONFIG.redirectUri)
    authUrl.searchParams.append('response_type', 'code')
    authUrl.searchParams.append('scope', ANAF_OAUTH_CONFIG.scope)

    // Store the current URL to return to after authentication
    localStorage.setItem('anaf_redirect_after_login', window.location.href)

    window.location.href = authUrl.toString()
}

export const processAnafCallback = async (code:  string) => {
    try {
      // Exchange the code for an access token using client credentials
        const tokenResponse = await axios.post(
            ANAF_OAUTH_CONFIG.tokenUrl,
            {
                grant_type   : ANAF_OAUTH_CONFIG.grantType,
                code,
                redirect_uri : ANAF_OAUTH_CONFIG.redirectUri,
            },
            {
                headers: {
                    'Content-Type'  : 'application/x-www-form-urlencoded',
                    'Authorization' : 'Basic ' +
                        btoa(`${ANAF_OAUTH_CONFIG.clientId}:${ANAF_OAUTH_CONFIG.clientSecret}`),
                },
            }
        )

      // Store the tokens
        const { access_token, refresh_token, expires_in } = tokenResponse.data

      // Save ANAF tokens to localStorage
        localStorage.setItem('anaf_tokens', JSON.stringify({
            accessToken  : access_token,
            refreshToken : refresh_token,
            expiresAt    : Date.now() + (expires_in * 1000),
        }))

      // Return to the page where the login was initiated
        const redirectUrl = localStorage.getItem('anaf_redirect_after_login') || '/'
        localStorage.removeItem('anaf_redirect_after_login')
        window.location.href = redirectUrl

        return tokenResponse.data
    } catch (error) {
        console.error('Error processing ANAF callback: ', error)
        throw error
    }
}

/**
 * Checks if user is authenticated with ANAF
 * @returns Boolean indicating if ANAF tokens exist and are not expired
 */
export const isAnafAuthenticated = () => {
    try {
        const tokensStr = localStorage.getItem('anaf_tokens')
        if (!tokensStr) return false

        const tokens = JSON.parse(tokensStr)
      // Check if token is expired
        if (tokens.expiresAt && tokens.expiresAt > Date.now()) {
            return true
        }
      // Token expired
        return false
    } catch (error) {
        console.error('Error checking ANAF authentication: ', error)
        return false
    }
}

/**
 * Logs out from ANAF by revoking the token
 */
export const logoutAnaf = async () => {
    try {
        const tokensStr = localStorage.getItem('anaf_tokens')
        if (!tokensStr) return

        const tokens = JSON.parse(tokensStr)

      // Revoke the token
        if (tokens.accessToken) {
            await axios.post(
                ANAF_OAUTH_CONFIG.revokeUrl,
                {
                    token           : tokens.accessToken,
                    token_type_hint : 'access_token',
                },
                {
                    headers: {
                        'Content-Type'  : 'application/x-www-form-urlencoded',
                        'Authorization' : 'Basic ' +
                            btoa(`${ANAF_OAUTH_CONFIG.clientId}:${ANAF_OAUTH_CONFIG.clientSecret}`),
                    },
                }
            )
        }

      // Remove ANAF tokens from localStorage
        localStorage.removeItem('anaf_tokens')
    } catch (error) {
        console.error('Error logging out from ANAF: ', error)
      // Still remove tokens even if revocation fails
        localStorage.removeItem('anaf_tokens')
    }
}