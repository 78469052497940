import * as React from 'react'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { processAnafCallback } from '../utils/anafOAuth'
import Container from '@mui/material/Container'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { i18n } from '@safefleet/react-common'

/**
 * Component that handles the OAuth callback from ANAF
 */
const AnafCallback: React.FC = () => {
    const location = useLocation()
    const [error, setError] = useState<string | null>(null)
    const [isProcessing, setIsProcessing] = useState<boolean>(true)

    const returnToApplication = () => {
        // Return to home page or stored redirec
        const redirectUrl = localStorage.getItem('anaf_redirect_after_login') || '/'
        localStorage.removeItem('anaf_redirect_after_login')
        window.location.href = redirectUrl
    }

    useEffect(() => {
        const handleCallback = async () => {
            try {
                const params = new URLSearchParams(location.search)
                const code = params.get('code')
                const errorParam = params.get('error')
                const errorDescription = params.get('error_description')

                if (errorParam) {
                    const errorMsg = errorDescription || errorParam
                    console.error('ANAF OAuth error:', errorMsg)
                    setError(errorMsg)
                    setIsProcessing(false)
                    return
                }

                if (!code) {
                    setError(i18n.t('No authorization code received'))
                    setIsProcessing(false)
                    return
                }

                await processAnafCallback(code)
                // The redirect should happen in processAnafCallback,
                // but if it doesn't, we can provide a button to return
                setIsProcessing(false)
            } catch (err: any) {
                console.error('Error processing ANAF callback:', err)
                setError(err.message || i18n.t('Failed to authenticate with ANAF'))
                setIsProcessing(false)
            }
        }

        handleCallback()
    }, [location])

    if (isProcessing) {
        return (
            <Container maxWidth="sm">
                <Box
                    sx={{
                        display        : 'flex',
                        flexDirection  : 'column',
                        alignItems     : 'center',
                        justifyContent : 'center',
                        height         : '100vh',
                    }}
                >
                    <CircularProgress />
                    <Typography variant="h6" sx={{ mt: 2 }}>
                        {i18n.t('Processing ANAF authentication...')}
                    </Typography>
                </Box>
            </Container>
        )
    }

    if (error) {
        return (
            <Container maxWidth="sm">
                <Box
                    sx={{
                        display        : 'flex',
                        flexDirection  : 'column',
                        alignItems     : 'center',
                        justifyContent : 'center',
                        height         : '100vh',
                    }}
                >
                    <Typography variant="h6" color="error">
                        {i18n.t('Authentication Error')}
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={returnToApplication}
                        sx={{ mt: 4 }}
                    >
                        {i18n.t('Return to Application')}
                    </Button>
                </Box>
            </Container>
        )
    }

    // Success state - show message briefly before redirect happens
    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    display        : 'flex',
                    flexDirection  : 'column',
                    alignItems     : 'center',
                    justifyContent : 'center',
                    height         : '100vh',
                }}
            >
                <Typography variant="h6" color="success.main">
                    {i18n.t('Authentication Successful')}
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }}>
                    {i18n.t('You will be redirected back to the application.')}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={returnToApplication}
                    sx={{ mt: 4 }}
                >
                    {i18n.t('Return to Application')}
                </Button>
            </Box>
        </Container>
    )
}

export default AnafCallback