import * as React from 'react'
import Toolbar, { ToolbarProps } from '@mui/material/Toolbar'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import logo from '../assets/img/logo_safefleet.png'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import BarUserMenu from './BarUserMenu'
import { unfinishedFeatures } from '../utils'
import { useSelectedUser } from '../hooks/user'
import Icon from './ui/Icon'
import carsIcon from '../assets/img/cars.svg'
import deviceIcon from '../assets/img/device.svg'
import reportsIcon from '../assets/img/reports.svg'
import statisticIcon from '../assets/img/statistic.svg'
import highwayIcon from '../assets/img/highway.svg'
import { i18n } from '@safefleet/react-common'
import CompanySelector from './CompanySelector'
import { spaces } from '../theme'
import avatarIcon from '../assets/img/avatar.svg'
import { SectionTitle } from './Text'
import { useNavigate } from 'react-router-dom'
import RssFeedComponent from './RssFeed'
import rssFeedAvailableSubDomains from '../utils/rssFeedAvailableSubDomains'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { auth } from '@safefleet/react-common'
import instancesServer from '../utils/instancesServer'
import anafIcon from '../assets/img/anaf.svg'
import { initiateAnafLogin, isAnafAuthenticated, logoutAnaf } from '../utils/anafOAuth'
import { useEffect } from 'react'

type AppBarAvatarProps = {
    avatar: string
}

const StyledToolbar = styled(Toolbar)<ToolbarProps>(({ theme }) =>{
    return {
        height: theme.custom.appBar.height,
    }
})

const useNavigateToRoot = () => {

    const navigate = useNavigate()

    return React.useCallback(() => {
        navigate('/')
        window.location.reload()
    }, [navigate])
}
const Logo = ({ onClick }:{onClick?: ()=> any}) => {
    return (
        <img
            src={logo}
            alt="Logo"
            style={{
                height      : "70%",
                marginRight : 50,
                cursor      : 'pointer',
            }}
            onClick={onClick}
        />
    )
}

type AppBarButtonProps = {
    text: string,
    icon?: any,
    active?: boolean
    onClick?: () => any,
}


const AppBarButton: React.FC<AppBarButtonProps> = ({ text, icon,onClick, active }) => {

    return (
        <Button
            color="secondary"
            onClick={onClick}
            sx={{
                flexDirection   : "column",
                paddingTop      : `${spaces.headerLine[2]}px`,
                height          : '100%',
                backgroundColor : active ? '#EDEDED' : 'white',
                marginLeft      : 3,
                display         : { xs: "none", sm: "flex" },
            }}
        >
            {icon}
            <span style={{ fontSize: 12, fontWeight: active ? 'bold' : 'normal', marginTop: 7 }}>
                {text}
            </span>
        </Button>
    )
}

const AppBarAvatar: React.FC<AppBarAvatarProps> = ({ avatar }) => (
    <Avatar src={avatar} sx={{ width: 45, height: 45 }} />
)

const getUserName = (user) => {
    const first_name = user?.first_name ?? ''
    const last_name = user?.last_name ?? ''

    return first_name + " " + last_name
}

const RssFeed = () => {
    const prefix = window.location.hostname.split('.')[0]

    if(rssFeedAvailableSubDomains.includes(prefix)) {
        return (
            <Box
                sx={{
                    display        : "flex",
                    flexDirection  : "column",
                    marginRight    : `${spaces.headerLine[2]}px`,
                    justifyContent : "flex-end",
                    alignItems     : "center",
                    marginTop      : "7px",
                }}
            >
                <RssFeedComponent />
            </Box>
        )
    } else {
        return <></>
    }
}

const SpaAppBar: React.FC = () => {

    const user = useSelectedUser()
    const avatar = user?.profile_photo || avatarIcon
    const userName = getUserName(user)
    const prefixUrl = window.location.hostname.split('.')[0]
    const authTokens: any = useSelector(auth.getJwtTokens)
    const accessToken = authTokens.accessToken
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [jasperURL, setJasperURL] = React.useState('')
    const [anafAuthenticated, setAnafAuthenticated] = React.useState(false)
    const open = Boolean(anchorEl)
    const reporstEnabled = (jasperURL && ['electrica', 'alpha', 'staging'].includes(prefixUrl)) ? true : false

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    // Handle ANAF login button click
    const handleAnafLogin = () => {
        if (anafAuthenticated) {
            // If already authenticated, show logout confirmation
            if (window.confirm(i18n.t('Are you sure you want to disconnect from ANAF?'))) {
                logoutAnaf()
                setAnafAuthenticated(false)
            }
        } else {
            // If not authenticated, start login flow
            initiateAnafLogin()
        }
    }

    useEffect(() => {
        // Check if user is already authenticated with ANAF
        setAnafAuthenticated(isAnafAuthenticated())

        const serverUrl = instancesServer?.[prefixUrl]
        axios.get(
            `${serverUrl}/safeapi/api/reports/jasper-login-url`,
            { headers: { Authorization: `Bearer ${accessToken}` } }
        ).then((response) => {
            setJasperURL(response.data.login_url)
        })

        // Check ANAF authentication status periodically
        const checkAnafAuth = () => {
            setAnafAuthenticated(isAnafAuthenticated())
        }

        const intervalId = setInterval(checkAnafAuth, 5 * 60 * 1000) // Check every 5 minutes

        return () => {
            clearInterval(intervalId)
        }
    }, [accessToken, prefixUrl])

    const goHome = useNavigateToRoot()
    const goJasper = () => {
        window.open(jasperURL)
    }

    return (
        <AppBar
            sx={{
                backgroundColor: "background.paper",

                zIndex  : (theme) => theme.zIndex.drawer + 1,
                padding : 0,
            }}
            position="fixed"
        >
            <StyledToolbar>
                <Logo onClick={goHome} />
                <AppBarButton
                    onClick={goHome}
                    active={true}
                    text={i18n.t("car fleet")}
                    icon={<Icon source={carsIcon} size={20} alt={"cars"} />}
                />
                {reporstEnabled ?
                    <AppBarButton
                        text={i18n.t("reports")}
                        onClick={goJasper}
                        icon={<Icon source={reportsIcon} size={18} alt={"reports"} />}
                    /> : <></>
                }
                <AppBarButton
                    text={anafAuthenticated ? i18n.t("ANAF Connected") : i18n.t("ANAF")}
                    onClick={handleAnafLogin}
                    active={anafAuthenticated}
                    icon={<Icon source={anafIcon} size={18} alt={"anaf"} />}
                />
                {unfinishedFeatures ? (
                    <>
                        <AppBarButton
                            text={i18n.t("devices")}
                            icon={<Icon source={deviceIcon} size={18} alt={"devices"} />}
                        />
                        <AppBarButton
                            text={i18n.t("statistics")}
                            icon={
                                <Icon source={statisticIcon} size={18} alt={"statistic"} />
                            }
                        />
                        <AppBarButton
                            text={i18n.t("hu-go")}
                            icon={<Icon source={highwayIcon} size={18} alt={"highway"} />}
                        />
                    </>
                ) : null}
                <Box
                    sx={{
                        flex           : 1,
                        display        : "flex",
                        flexDirection  : "row",
                        justifyContent : "flex-end",
                        height         : "100%",
                        alignItems     : "center",
                    }}
                >
                    <RssFeed />
                    <Box
                        sx={{
                            display        : { xs: "none", sm: "flex" },
                            flexDirection  : "column",
                            marginRight    : `${spaces.headerLine[2]}px`,
                            justifyContent : "flex-end",
                            alignItems     : "center",
                            marginTop      : "7px",
                        }}
                    >
                        <SectionTitle
                            sx={{
                                display   : "flex",
                                alignSelf : "flex-end",
                            }}
                        >
                            {userName}
                        </SectionTitle>
                        <CompanySelector />
                    </Box>
                    <Box
                        onClick={handleClick}
                    >
                        <AppBarAvatar avatar={avatar}/>
                    </Box>
                </Box>
                <BarUserMenu
                    open={open}
                    handleClose={handleClose}
                    anchorEl={anchorEl}
                    avatar={avatar}
                />
            </StyledToolbar>
        </AppBar>
    )
}

export default SpaAppBar
